import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user/list';
import Scrollbar from 'src/components/Scrollbar';
import HomePageTableHead from './home-page-table-head';
import HomePageTableToolbar from './home-page-table-toolbar';
import { styled } from '@material-ui/styles';
import { Verified } from '@material-ui/icons';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'floorPrice', label: 'Floor Price', alignRight: false },
  { id: 'volume', label: 'Volume', alignRight: false },
];

const ThumbImgStyle = styled('img')(({ theme }) => ({
    width: 64,
    height: 64,
    objectFit: 'cover',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusSm
  }));

// ----------------------------------------------------------------------

export default function HomePageTable() {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const userList = [
    {
      id: 1,
      rank:1,
      name: 'sambhu@skyniche.com',
      floorPrice: '0.4 ETH',
      volume:'92 ETH',
      verified:false,
      avatarUrl: 'https://i.seadn.io/s/raw/files/d066c1a36491f526a36648df8754b342.jpg?auto=format&dpr=1&w=136&h=136&fr=1'
    },
    {
      id: 2,
      rank:3,
      name: 'anoosha@skyniche.com',
      floorPrice: '< 0.01 ETH',
      volume:'6 ETH',
      verified:true,
      avatarUrl: 'https://i.seadn.io/s/raw/files/4fce98acde508d20a97a6e3baae442d0.png?auto=format&dpr=1&w=136&h=136&fr=1'
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  return (
      <Container>
        <Card sx={{background:'#161c24',boxShadow:'none' }}>
          <HomePageTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar sx={{
              [theme.breakpoints.up('lg')]: {
                mt: 0, 
                mb:0,
              },
              [theme.breakpoints.up('md')]: {
                mt: 0, 
                mb:0,
              },
              [theme.breakpoints.up('sm')]: {
                mt: 0, 
                mb:0,
              },
              [theme.breakpoints.up('xs')]: {
                mt: 7,
              },
            }}>
            <TableContainer sx={{ minWidth: 600,whiteSpace:'nowrap' }}>
              <Table>
                <HomePageTableHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody sx={{borderTop:'1px solid rgba(255, 255, 255, 0.15)'}}>
                  {userList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, floorPrice, rank, avatarUrl,volume,verified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                        >
                          <TableCell align="left"><Typography sx={{ml:1,mb:3,mt:3}} variant="subtitle2">{rank}</Typography></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" sx={{ ml: -3 }} alignItems="center" spacing={2}>
                              <ThumbImgStyle alt={name} src={avatarUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                              {verified === true && (
                                <Verified sx={{ color: 'primary.main',fontSize: '1rem',mt:0.6, }}/>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell align="left"><Typography sx={{ml:1}}>{floorPrice}</Typography></TableCell>
                          <TableCell align="left"><Typography sx={{ml:0.5}}>{volume}</Typography></TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
  );
}