import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Stack, Tabs, Tab } from '@material-ui/core';
import { useState } from 'react';

// ----------------------------------------------------------------------
const CustomTab = styled(Tab)(({ theme }) => ({
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  boxShadow: 'none', //theme.shadows[5],
  minHeight: 'fit-content',
  indicator: {
    display: 'none',
  },
  height: 47,
  fontSize: 16,
  borderRadius: 'none', //20
  // border: '1.6px solid #0f171e', //newlyadded
  // marginTop: 5, //5
  color: theme.palette.text.secondary, // Set non-selected tab text color to grey
  '&:hover': {
    color: '#fff',
  },
  '&.Mui-selected': {
    background: 'rgba(255, 255, 255, 0.15)',
    color: '#fff',
  },
  '&:first-child': {
    borderRadius: '0.75rem',
    // borderLeft: `1.5px solid ${theme.palette.primary.main}`,
  },
  '&:nth-child(2)': {
    borderRadius: '0.75rem',
    borderLeft: '0',
  },
  '&:nth-child(3)': {
    borderRadius: '0.75rem',
    borderLeft: '0',
  },
  '&:last-child': {
    borderRadius: '0.75rem',
    borderLeft: '0',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: `0 !important`,
    minWidth: '20% !important',
  },
  [theme.breakpoints.down('lg')]: {
    marginRight: `0 !important`,
    minWidth: '20% !important',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: `0 !important`,
    minWidth: '30.33% !important',
  },
}));

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
// ----------------------------------------------------------------------

HomePageTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function HomePageTableToolbar({ numSelected, filterName, onFilterName }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const [valueTab, setValueTab] = useState('one');
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const [daysvalueTab, setDaysValueTab] = useState('1');
  const handleDaysChange = (event, newValue) => {
    setDaysValueTab(newValue);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
        })
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column',md:'row',sm:'row', lg: 'row' },justifyContent:'space-between',width:'100%' }}>
        <Tabs
          sx={{
            borderRadius: '12px',
            background: 'rgba(255, 255, 255, 0.04)',
            width:'fit-content',
            [theme.breakpoints.up('lg')]: {
              mr: 3,
              mt: 0,
              mb: 0,
            },
            [theme.breakpoints.up('md')]: {
              mr: 3,
              mt: 0,
              mb: 0,
            },
            [theme.breakpoints.up('sm')]: {
              mr: 3,
              mt: 0,
              mb: 0,
            },
            [theme.breakpoints.up('xs')]: {
              mr: 0,
              mt: 3,
              mb: 3,
            },
          }}
          value={valueTab}
          variant="scrollable"
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="none"
          aria-label="secondary tabs example"
        >
          <CustomTab value="one" label={'Trending'} />
          <CustomTab value="two" label={'Top'} />
        </Tabs>
        <Tabs
          sx={{
            borderRadius: '12px',
            background: 'rgba(255, 255, 255, 0.04)',
            display: 'flex',
            justifyContent: 'flex-end',
            width:'fit-content',
          }}
          value={daysvalueTab}
          variant="scrollable"
          onChange={handleDaysChange}
          textColor="secondary"
          indicatorColor="none"
          aria-label="secondary tabs example"
        >
          <CustomTab value="1" label={'1h'} />
          <CustomTab value="2" label={'6h'} />
          <CustomTab value="3" label={'24h'} />
          <CustomTab value="4" label={'7d'} />
        </Tabs>
      </Box>
    </RootStyle>
  );
}
