import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';
import NavbarNew from './NavbarNew';
import CreateItemNavbar from 'src/pages/Joy-home-page/Create-Item/Create-Item-Navbar/create-item-navbar';
import CreateCollectionNavbar from 'src/pages/Joy-home-page/Create-Item/Create-Item-Navbar/create-collection-navbar';
import CreateNFTNavbar from 'src/pages/Joy-home-page/Create-Item/Create-Item-Navbar/create-nft-navbar';
import CreateCollectionNFTNavbar from 'src/pages/Joy-home-page/Create-Item/Create-Item-Form/Create-NFT/create-nft-navbar';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isInner = pathname === '/inner-page';
  const isInnerNFT = pathname === '/inner-page-NFT';
  const isProfileUser = pathname === '/user-profile';
  const isCreate = pathname === '/create-item';
  const isCreateNFTItem = pathname === '/create-item-NFT';
  const isCreateNFT = pathname === '/create-nft-model';
  const createAI = pathname === '/create-ai-model';
  const dropCollection = pathname === '/create-collection';
  const dropCollectionNFt = pathname === '/create-collection-NFT';

  return (
    <>
      {!isCreate && !isCreateNFTItem &&(<NavbarNew />)}
      {createAI && (<CreateItemNavbar />)}
      {isCreateNFT && (<CreateNFTNavbar />)}
      {dropCollection && (<CreateCollectionNavbar />)}
      {dropCollectionNFt && (<CreateCollectionNFTNavbar />)}
      {/* <MainNavbar /> */}
      <div>
        <Outlet />
      </div>

      {isInner && (
        <MainFooter />
      )
        // <Box
        //   sx={{
        //     py: 5,
        //     textAlign: 'center',
        //     position: 'relative',
        //     bgcolor: 'background.default'
        //   }}
        // >
        //   <Container maxWidth="lg">
        //     <ScrollLink to="move_top" spy smooth>
        //       <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
        //     </ScrollLink>

        //     <Typography variant="caption" component="p">
        //       © All rights reserved
        //       <br /> made by &nbsp;
        //       <Link href="#">Rostech Innovations.</Link>
        //     </Typography>
        //   </Container>
        // </Box>
        }
      {isProfileUser &&(
        <MainFooter />
      )}
      {isInnerNFT &&(
        <MainFooter />
      )}
      
    </>
  );
}
