import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useCallback, useState } from 'react';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button, Dialog } from '@material-ui/core';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
// layouts
import AuthLayout from '../../../layouts/AuthLayout';
// components
import Page from '../../../components/Page';
import { MHidden, MIconButton } from '../../../components/@material-extend';
import Logo from '../../../../src/Logo/apple-touch-icon.png';
import { ArrowBack, Close } from '@material-ui/icons';
import JoyLoginForm from './joy-login-form';
import { VerifyCodeForm } from 'src/components/authentication/verify-code';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 540,
  margin: 'auto',
  display: 'flex',
  minHeight: 'fit-content',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom:20,
  overflow:'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },'&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: '4px',
  },
}));

// ----------------------------------------------------------------------

export default function JoyLoginPopUp({openLogin, setOpenLogin,handleCloseLogin,  }) {

  const [emailEntered, setEmailEntered] = useState(false);

   function handleBack () {
    setEmailEntered(false)
   }
  const [verificationCode, setVerificationCode] = useState(false);

   function handleVerificationCodeBack () {
    setVerificationCode(false)
   }

   const [welcome, setWelcome] = useState(false);

  return (
    
    <Dialog open={openLogin} sx={{height:'100%',width:'100%',overflow:'auto',
    '&::-webkit-scrollbar': {
                    width: '0.4em',
                  },'&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '4px',
                  },}}>
        <Box sx={{display:'flex',justifyContent: emailEntered ? 'space-between' :'flex-end',mt:2,pr:1,pl:1,overflow:'auto',
        '&::-webkit-scrollbar': {
                        width: '0.4em',
                      },'&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                        borderRadius: '4px',
                      },}}>
              {emailEntered && !verificationCode && !welcome &&(<MIconButton onClick={handleBack}>
                <ArrowBack sx={{color:'#fff',fontSize:'24px',fontWeight:'600',}}/>
            </MIconButton>)}
              {verificationCode && !welcome &&(<MIconButton onClick={handleVerificationCodeBack}>
                <ArrowBack sx={{color:'#fff',fontSize:'24px',fontWeight:'600',}}/>
            </MIconButton>)}
            {!emailEntered ?(<MIconButton onClick={handleCloseLogin}>
                <Close sx={{color:'#fff',fontSize:'24px',fontWeight:'600',}}/>
            </MIconButton>) : !verificationCode ? (<MIconButton onClick={handleCloseLogin}>
                <Close sx={{color:'#fff',fontSize:'24px',fontWeight:'600',}}/>
            </MIconButton>) : !welcome ?(
              <MIconButton onClick={handleCloseLogin}>
              <Close sx={{color:'#fff',fontSize:'24px',fontWeight:'600',}}/>
            </MIconButton>
            ) : ''}
        </Box>
        <Container maxWidth="sm">     
            <ContentStyle>  
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            {welcome ?(<Tooltip placement="top">
                <Box sx={{ textAlign: 'center' }}>
                  <Box component="img" src={Logo} sx={{ width: 95, height: 95 ,mb:2,mt:4}} />
                </Box>
              </Tooltip>) : <Tooltip placement="top">
                <Box sx={{ textAlign: 'center' }}>
                  <Box component="img" src={Logo} sx={{ width: 95, height: 95 ,mb:2}} />
                </Box>
              </Tooltip>}
              {!emailEntered ? (<Typography variant="h4" gutterBottom>
                Connect to Joy Marketplace
              </Typography>) : 
              !verificationCode ? (
                <Typography variant="h4" gutterBottom>
                  Create your Wallet
                </Typography>
              ) : !welcome ? (<Typography variant="h4" gutterBottom>
                  Enter Code
              </Typography>) : (<Typography variant="h4" gutterBottom>
                Welcome To Joy Marketplace
              </Typography>)}
            </Box>
              <JoyLoginForm setOpenLogin={setOpenLogin} handleCloseLogin={handleCloseLogin} emailEntered={emailEntered} setEmailEntered={setEmailEntered} verificationCode={verificationCode} setVerificationCode={setVerificationCode} welcome={welcome} setWelcome={setWelcome}/>
            </ContentStyle>
          </Container>
      </Dialog>
      
  );
}
