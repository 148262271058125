import faker from 'faker';
import { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import roundArrowRightAlt from '@iconify/icons-ic/round-arrow-right-alt';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import Image1 from '../../../../src/Images/Designer4.png';
import Image2 from '../../../../src/Images/Designer5.png';
import Image3 from '../../../../src/Images/Designer6.png';
import Image4 from '../../../../src/Images/Designer7.png';
import Image5 from '../../../../src/Images/Designer8.png';
import Image6 from '../../../../src/Images/Designer9.png';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box, Card, Button, Container, Typography, IconButton, Stack } from '@material-ui/core';
//

import { CarouselControlsArrowsBasic2 } from 'src/components/carousel';
import { MotionInView, varFadeIn, varFadeInDown, varFadeInUp } from 'src/components/animate';
import { Verified } from '@material-ui/icons';
import SliderControl from '../Home-page-first-slider/slider-control';
import { collectionsAddress,NFTmodelsAddress, REST_API } from 'src/constants/DefaultConstantvalues';
import { ethers } from 'ethers';
import DeployNFTModel from 'src/artifacts/contracts/DeployNFTModel.sol/DeployNFTModel.json';
import NFTModels from 'src/artifacts/contracts/NFTModels.sol/NFTModels.json';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';
import { MCircularProgress } from 'src/components/@material-extend';
// ----------------------------------------------------------------------

const MEMBERS1 = [
  {
    name: faker.name.findName(),
    totalVolume:'22 ETH',
    floor: '0.05 ETH',
    avatar: 'https://airnfts.s3.amazonaws.com/nft-images/20220321/11_GIF_1647858995373.gif'
  },
  {
    name: faker.name.findName(),
    totalVolume:'19K ETH',
    floor: '0.03 ETH',
    avatar: 'https://media2.giphy.com/media/IOfMu6BapZcVsmHKZU/giphy.gif?cid=6c09b952cj26pj6kt3a7rwqyalpwj8gs79v6fog1sszg93ns&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=g'
  },
  {
    name: faker.name.findName(),
    totalVolume:'388 ETH',
    floor: '100 ETH',
    avatar: 'https://unlock-protocol.com/images/blog/dynamic-nft/tales-of-elatora.gif'
  },
  {
    name: faker.name.findName(),
    totalVolume:'107 ETH',
    floor: '0.04 ETH',
    avatar: 'https://i.pinimg.com/originals/e7/f5/00/e7f500f0ee861d58826836cc5e63a2ce.gif'
  },
];

// ----------------------------------------------------------------------

MemberCard.propTypes = {
  member: PropTypes.object
};

const MediaComponent = ({ filePath, mimeType }) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Box sx={{ width: '100%', borderTopRightRadius: 1.5, borderTopLeftRadius: 1.5, height: '190px', overflow: 'hidden' }}>
      {filePath && mimeType?.startsWith('video') ? ( // && filePath.includes('video')
        <video
          src={filePath}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          // controls
          loop
          muted
          autoPlay={isPlaying}
        />
      ) : (
        <img
          src={filePath}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="media"
        />
      )}
    </Box>
  );
};

function MemberCard({ member }) {
  const { name, supply, price, aiModelimages, floor, avatar,totalVolume } = member;
  const navigate = useNavigate();
  function handleInnerPage(member){
    navigate(PATH_PAGE.buyItemNFT,{state:{memberData:member}})
  }

  return (
    <Card key={name} sx={{ mx: 1.5,boxShadow:'none' }} onClick={()=>handleInnerPage(member)}>
      {/* <Box component="img" src={REST_API + aiModelimages[0]?.filePath} sx={{ width: '100%', borderTopRightRadius: 1.5,borderTopLeftRadius:1.5,height:'190px',objectFit:'cover' }} /> */}
      <div>
        <MediaComponent filePath={aiModelimages[0]?.filePath} mimeType={aiModelimages[0]?.mimeType} />
      </div>
      <Stack direction="row">
          <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5,display:'flex',justifyContent:'flex-start',pl:3, }}>
            {name}
          </Typography>
          <Verified sx={{ color: 'primary.main',ml:1,fontSize: '1rem',mt:2.3  }}/>
      </Stack>
      <Box sx={{display:'flex',justifyContent:'space-between',pl:3,pr:3,mb:0.3,mt:1.5}}>
        <Typography variant="body2" sx={{  color: 'text.secondary' }}>
          Floor
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Total Volume
        </Typography>
      </Box>
      <Box sx={{display:'flex',justifyContent:'space-between',pl:3,pr:3,mb:1.5}}>
        <Typography variant="subtitle1" sx={{  color: '#fff' }}>
          {price}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#fff' }}>
          {supply}
        </Typography>
      </Box>
      
      
    </Card>
  );
}

export default function HomePageCategoryTwoNFT() {
  const carouselRef = useRef();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [MEMBERS,setMEMBERS] = useState([])

  const getAIModels = async() =>{
    setIsLoading(true)
    try{
      if (!window.ethereum) {
        enqueueSnackbar('Please install MetaMask', { variant: 'error' });
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      
      console.log('NFTmodelsAddress', NFTmodelsAddress);
      const AIModelsContract = new ethers.Contract(NFTmodelsAddress, NFTModels.abi, signer);
      const getAllAIModelsResult = await AIModelsContract.getAllAIModels();
      console.log('getAllAIModelsResult--', getAllAIModelsResult);
      
      const allModelsData = await Promise.all(getAllAIModelsResult.map(async (aiModeldata) => {
        const aiModelContract = new ethers.Contract(aiModeldata.contractAddress, DeployNFTModel.abi, signer);
      
        const [aiModelDatas, earnings, tokenOwners] = await Promise.all([
          aiModelContract.getContract(),
          aiModelContract.getCreatorEarnings(aiModeldata.id),
          aiModelContract.getTokenOwners(aiModeldata.id)
        ]);
      
        console.log('aiModelDatas---', aiModelDatas);
      
        const modelData = {
          collectionName: aiModelDatas[0],
          blockchain: aiModelDatas[1],
          tokenSymbol: aiModelDatas[2],
          name: aiModelDatas[3],
          buyers: aiModelDatas[8],
          supply: parseInt(aiModeldata.supply),
          price: parseFloat(ethers.utils.formatUnits(aiModeldata.price, 'ether')),
          tokenOwners: tokenOwners,
          contractAddress: aiModeldata.contractAddress,
          category: aiModeldata.category,
          description: aiModeldata.description,
          link: aiModeldata.link,
          tabs: aiModeldata.additionalTabs,
          ownerEmail: aiModeldata.ownerEmail,
          owner: aiModeldata.owner,
          startDate: aiModeldata.startDate,
          endDate: aiModeldata.endDate,
          status: aiModeldata.status,
          id: aiModeldata.id,
          aiModelimages: aiModeldata.aiModelimages,
          creatorEarnings: ethers.utils.formatEther(earnings)
        };
      
        return modelData;
      }));
      
      console.log('allModelsData--', allModelsData);
      

      let filteredModels;
      filteredModels = allModelsData?.filter((item) => item.category === 'Gaming');
      console.log('filteredModels---',filteredModels);
      setMEMBERS(filteredModels);
    }catch(error){
      console.log('getAllAIModels Error : ',error);
      setMEMBERS([])
    }finally{
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    getAIModels();
  },[])

  const settings = {
    speed: 500,
    slidesToShow: MEMBERS?.length < 4 ? MEMBERS?.length : 4,
    centerMode: true,
    background:'#161c24',
    centerPadding: '0 10px',
    rtl: Boolean(theme.direction === 'rtl'),
    arrows: false,
    dots: false,  
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: MEMBERS?.length < 3 ? MEMBERS?.length : 3 }
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: MEMBERS?.length < 2 ? MEMBERS?.length : 2 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 }
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 10, textAlign: 'center' }}>
      <MotionInView variants={varFadeInUp}>
        <Typography variant="h3" sx={{ mb: 7,display:'flex',justifyContent:'flex-start' }}>
        Trending in Gaming
        </Typography>
      </MotionInView>

      <Box sx={{ position: 'relative',background:'#161c24',cursor:'pointer' }}>
      {isLoading ? 
          <Stack direction={{ xs: 'row', sm: 'row' }} justifyContent={'center'} alignItems={'center'} >
            <MCircularProgress sx={{ color: '#fff' }} size={24} /> 
            <Typography variant='body1' sx={{ml:2}}>Loading...</Typography>
          </Stack>
          : 
          <Slider ref={carouselRef} {...settings}>
            {MEMBERS?.map((member) => (
              <MotionInView key={member.name} variants={varFadeIn}>
                <MemberCard key={member.name} member={member} />
              </MotionInView>
            ))}
          </Slider>
        }
        <SliderControl
          onNext={handleNext}
          onPrevious={handlePrevious}
          sx={{ transform: 'translateY(-64px)' }}
        />
      </Box>
    </Container>
  );
}
