import faker from 'faker';
import { useEffect, useRef,useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import roundArrowRightAlt from '@iconify/icons-ic/round-arrow-right-alt';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
// material
import { alpha, useTheme } from '@material-ui/core/styles';
import { Box, Card, Button, Container, Typography, IconButton, Stack, Tabs, Tab } from '@material-ui/core';
//


import { varFadeIn, varFadeInUp, MotionInView, varFadeInDown } from 'src/components/animate';
import { CarouselControlsArrowsBasic2 } from 'src/components/carousel';
import { styled } from '@material-ui/styles';
import { Verified, ViewList, ViewModule } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';
import { collectionsAddress, AImodelsAddress, REST_API } from 'src/constants/DefaultConstantvalues';
import { ethers } from 'ethers';
import DeployAIModel from 'src/artifacts/contracts/DeployAIModel.sol/DeployAIModel.json';
import Collections from 'src/artifacts/contracts/Collections.sol/Collections.json';
import AIModels from 'src/artifacts/contracts/AIModels.sol/AIModels.json';
import { MCircularProgress } from 'src/components/@material-extend';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

// const CustomTab = styled(Tab)(({ theme }) => ({
//   width: "fit-content",
//   // marginRight: theme.spacing(0.5), 
//   borderRadius: "10px", 
//   // boxShadow: theme.shadows[5], 
//   background: "transparent", 
//   paddingLeft: theme.spacing(3),
//   paddingRight: theme.spacing(3),
//   paddingTop: theme.spacing(1),
//   paddingBottom: theme.spacing(1),
//   minHeight: "fit-content",
//   indicator: {
//     display: "none",
//   },
//   height: 38,
//   fontSize: 16,
//   // border: "1.6px solid #0f171e",
//   marginTop: 0,
//   color: theme.palette.text.primary,
//   "&:hover": {
//     background: "rgba(255, 255, 255, 0.12)",
//   },
//   "&.Mui-selected": {
//     background: 'rgba(255, 255, 255, 0.12)',
//     color: "#fff",
//   },
//   "&:first-child": {
//     borderTopLeftRadius: "7px",
//     borderBottomLeftRadius: "7px",
//   },
//   "&:not(:first-child)": {
//     marginLeft: theme.spacing(-3),
//     marginBottom: theme.spacing(4), 
//   },
//   // Add text color
//   "& .MuiTab-wrapper": {
//     color: "inherit",
//   },
//   // Add font size for label
//   "& .MuiTab-label": {
//     fontSize: "16px", // Adjust the font size as needed
//   },
// }));


const ContentItemStyle = styled('div')(({ theme }) => ({
    bottom: 0,
    zIndex: 9,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottomLeftRadius: 10,
    // backdropFilter: 'blur(8px)',
    // WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderBottomRightRadius: 10,
    justifyContent: 'space-between',
    backgroundColor: alpha(theme.palette.grey[900], 0.50),
    flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row'
  }));

const MEMBERS1 = [
  {
    role: 'Byte Art Brains',
    avatar: 'https://studio.pinotspalette.com/briercreek/images/ai-art-1024x683.jpg',
    floor:'0.27 ETH',
    contractAddress:1
  },
  {
    role: 'Quantum Canvas',
    avatar: 'https://images.squarespace-cdn.com/content/v1/62ec2bc76a27db7b37a2b32f/2b535c37-5738-42a7-8751-32ad102b1801/ai-music.jpg?format=1000w',
    floor:'0.7 ETH',
    contractAddress:2
  },
  // {
  //   role: 'Digi Minds Forge',
  //   avatar: 'https://static.vecteezy.com/system/resources/previews/027/160/071/non_2x/generative-ai-street-art-with-keys-and-musical-instruments-silhouettes-ink-colorful-graffiti-art-with-melted-paint-photo.jpg',
  //   floor:'0.56 ETH',
  //   contractAddress:3
  // },
  // {
  //   role: 'Pixel Creations',
  //   avatar: 'http://joyai.gettridant.com/static/media/Designer16.651270bc.png',
  //   floor:'0.25 ETH',
  //   contractAddress:4
  // },
  // {
  //   role: 'Data Dream',
  //   avatar: 'https://i.seadn.io/gcs/files/9508acda43367f3c6ac78fd8d55bbea5.png?auto=format&dpr=1&h=500&fr=1',
  //   floor:'0.11 ETH',
  //   contractAddress:5
  // },
  // {
  //   role: 'Quantum Studios',
  //   avatar: 'https://news.ubc.ca/wp-content/uploads/2023/08/AdobeStock_559145847.jpeg',
  //   floor:'0.34 ETH',
  //   contractAddress:6
  // }
];

// ----------------------------------------------------------------------

MemberCard.propTypes = {
  member: PropTypes.object
};

function MemberCard({ member }) {
  const { name, role, avatar,floor,aiModelimages,blockchain,category,collectionName, description, price, supply, tokenSymbol} = member;
  const navigate = useNavigate();
  function handleInnerPage(member){
    navigate(PATH_PAGE.buyItem,{state:{memberData:member}})
  }

  return (
    <Card key={name} sx={{ mx: 1.5, boxShadow:'none'}} onClick={()=>handleInnerPage(member)}>
      {/* <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        {role}
      </Typography> */}
      {/* <Box component="img" src={avatar} sx={{ width: '100%', borderRadius: 1.5 }} /> */}
      <Box sx={{ position: 'relative', zIndex: 0 }}>
        <Box component="img" src={aiModelimages?.length>0 ? aiModelimages[0]?.filePath : avatar} sx={{ width: '100%',height: 350, borderRadius: 1.5, objectFit:'cover' }} />

          <ContentItemStyle>

            <Stack direction="column" alignItems="flex-start" sx={{ml:1}}>
                <Stack direction="row">
                    <Typography variant="h6" sx={{ color: 'common.white', ml: 0 }} onClick={()=>handleInnerPage(member)}>
                        {name}
                    </Typography>
                    <Verified sx={{ color: 'primary.main',ml:1,fontSize: '1rem',mt:0.6 }}/>
                </Stack>
                <Typography variant="body2" sx={{ color: 'common.white', }}>
                    Floor: {price ? price : 0} ETH
                </Typography>
            </Stack>
            
          </ContentItemStyle>
        </Box>
      {/* <Box sx={{ mt: 2, mb: 1 }}>
        {[facebookFill, instagramFilled, linkedinFill, twitterFill].map((social, index) => (
          <IconButton key={index}>
            <Icon icon={social} width={20} height={20} />
          </IconButton>
        ))}
      </Box> */}
    </Card>
  );
}

export default function HomeFirstSlider({valueTab}) {
  const carouselRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false)
  const [MEMBERS,setMEMBERS] = useState([])
  const [category,setCategory] = useState('AI Models')

  const getAIModels = async () => {
    setIsLoading(true);
    try {
      if (!window.ethereum) {
        enqueueSnackbar('Please install MetaMask', { variant: 'error' });
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      console.log('AImodelsAddress', AImodelsAddress);
      const AIModelsContract = new ethers.Contract(AImodelsAddress, AIModels.abi, signer);
      const getAllAIModelsResult = await AIModelsContract.getAllAIModels();
      console.log('getAllAIModelsResult--', getAllAIModelsResult);
  
      const modelDataPromises = getAllAIModelsResult.map(async (aiModeldata) => {
        const aiModelContract = new ethers.Contract(aiModeldata.contractAddress, DeployAIModel.abi, signer);
        const aiModelDatas = await aiModelContract.getContract();
        const earnings = await aiModelContract.getCreatorEarnings(aiModeldata.id);
        const tokenOwners = await aiModelContract.getTokenOwners(aiModeldata.id);
        
        return {
          collectionName: aiModelDatas[0],
          blockchain: aiModelDatas[1],
          tokenSymbol: aiModelDatas[2],
          name: aiModelDatas[3],
          buyers: aiModelDatas[8],
          supply: parseInt(aiModeldata.supply),
          price: parseFloat(ethers.utils.formatUnits(aiModeldata.price, 'ether')),
          tokenOwners: tokenOwners,
          contractAddress: aiModeldata.contractAddress,
          category: aiModeldata.category,
          description: aiModeldata.description,
          link: aiModeldata.link,
          tabs: aiModeldata.additionalTabs,
          ownerEmail: aiModeldata.ownerEmail,
          owner: aiModeldata.owner,
          startDate: aiModeldata.startDate,
          endDate: aiModeldata.endDate,
          status: aiModeldata.status,
          id: aiModeldata.id,
          aiModelimages: aiModeldata.aiModelimages,
          creatorEarnings: ethers.utils.formatEther(earnings)
        };
      });
  
      const allModelsData = await Promise.all(modelDataPromises);
      console.log('allModelsData--', allModelsData);
  
      const filterByCategory = (models, category) => models.filter(item => item.category === category);
  
      let filteredModels;
      switch (valueTab) {
        case '2':
          filteredModels = filterByCategory(allModelsData, 'Customer Interaction models');
          break;
        case '3':
          filteredModels = filterByCategory(allModelsData, 'Image and Video Processing models');
          break;
        case '4':
          filteredModels = filterByCategory(allModelsData, 'NLP AI models');
          break;
        case '5':
          filteredModels = filterByCategory(allModelsData, 'Predictive Analytics Models');
          break;
        case '6':
          filteredModels = filterByCategory(allModelsData, 'Voice Recognition Systems');
          break;
        case '8':
          filteredModels = filterByCategory(allModelsData, 'Personalization AI models');
          break;
        case '9':
          filteredModels = filterByCategory(allModelsData, 'AI-Driven Marketing Models');
          break;
        case '10':
          filteredModels = filterByCategory(allModelsData, 'Fraud Detection Models');
          break;
        case '11':
          filteredModels = filterByCategory(allModelsData, 'Supply Chain Optimization Models');
          break;
        case '12':
          filteredModels = filterByCategory(allModelsData, 'Environmental and Climate Models');
          break;
        case '13':
          filteredModels = filterByCategory(allModelsData, 'Robotics Control Models');
          break;
        case '14':
          filteredModels = filterByCategory(allModelsData, 'Educational AI Models');
          break;
        case '15':
          filteredModels = filterByCategory(allModelsData, 'AI-Enabled Financial Planning Models');
          break;
        case '16':
          filteredModels = filterByCategory(allModelsData, 'Creative AI Models');
          break;
        case '17':
          filteredModels = filterByCategory(allModelsData, 'Various Machine Learning Techniques');
          break;
        case '18':
          filteredModels = filterByCategory(allModelsData, 'Quantum Machine Learning');
          break;
        case '19':
          filteredModels = filterByCategory(allModelsData, 'AI for Space Exploration');
          break;
        case '20':
          filteredModels = filterByCategory(allModelsData, 'Biotech and Genomic AI');
          break;
        case '21':
          filteredModels = filterByCategory(allModelsData, 'AI in Renewable Energy');
          break;
        case '22':
          filteredModels = filterByCategory(allModelsData, 'AI for Urban Planning');
          break;
        case '23':
          filteredModels = filterByCategory(allModelsData, 'Emotion Recognition Systems');
          break;
        case '24':
          filteredModels = filterByCategory(allModelsData, 'AI for Autonomous Vehicles');
          break;
        case '25':
          filteredModels = filterByCategory(allModelsData, 'Edge AI');
          break;
        case '26':
          filteredModels = filterByCategory(allModelsData, 'AI for Disaster Response');
          break;
        case '27':
          filteredModels = filterByCategory(allModelsData, 'AI in Agriculture');
          break;
        case '28':
          filteredModels = filterByCategory(allModelsData, 'Neuroscience and AI');
          break;
        default:
          filteredModels = allModelsData;
      }
      console.log('filteredModels---',filteredModels);
      setMEMBERS(filteredModels);
    }catch(error){
      console.log('getAllAIModels Error : ',error);
      setMEMBERS([])
    }finally{
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    getAIModels();
  },[valueTab])
  
  const settings = {
    speed: 500,
    slidesToShow: MEMBERS?.length < 4 ? MEMBERS?.length : 4,
    centerMode: true,
    background:'#161c24',
    centerPadding: '0 10px',
    rtl: Boolean(theme.direction === 'rtl'),
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,  
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: MEMBERS?.length < 3 ? MEMBERS?.length : 3 }
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: MEMBERS?.length < 2 ? MEMBERS?.length : 2 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 }
      }
    ]
  };
  

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 10, textAlign: 'center' }}>
      {/* <MotionInView variants={varFadeInDown}>
        <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
          Dream team
        </Typography>
      </MotionInView> */}

      {/* <MotionInView variants={varFadeInUp}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Great team is the key
        </Typography>
      </MotionInView>

      <MotionInView variants={varFadeInUp}>
        <Typography
          sx={{
            mb: 10,
            mx: 'auto',
            maxWidth: 630,
            color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white')
          }}
        >
          Minimal will provide you support if you have any problems, our support team will reply within a day and we
          also have detailed documentation.
        </Typography>
      </MotionInView> */}
      {/* <Stack direction={'row'} spacing={2}>
      <Tabs
        sx={{
          [theme.breakpoints.up('lg')]: {
            mt: -6, 
          },
          [theme.breakpoints.up('md')]: {
            mt: -6, 
          },
          [theme.breakpoints.up('sm')]: {
            mt: 0, 
          },
          [theme.breakpoints.up('xs')]: {
            mt: 0, 
          },
        }}
        value={valueTab}
        variant="scrollable"
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="none"
        aria-label="secondary tabs example">
        <CustomTab value="one" label={'All'} />
        <CustomTab value="two" label={'Art'} />
        <CustomTab value="three" label={'Gaming'} />
        <CustomTab value="four" label={'Memberships'} />
        <CustomTab value="five" label={'PFPs'} />
        <CustomTab value="six" label={'Photography'} />
        <CustomTab value="seven" label={'Music'} />
      </Tabs>
      </Stack> */}
      {isLoading ?
        <Stack direction={{ xs: 'row', sm: 'row' }} >
          <MCircularProgress sx={{ color: '#fff' }} size={24} /> 
          <Typography variant='body1' sx={{mb:1.5,ml:2}}>Loading {category}...</Typography>
        </Stack>
        :
        <Box sx={{ position: 'relative',cursor:'pointer' }}>
          <Slider ref={carouselRef} {...settings}>
            {MEMBERS?.map((member,index) => {
              return(
                <MotionInView key={member.contractAddress} variants={varFadeIn}>
                  <MemberCard key={member.contractAddress} member={member} />
                </MotionInView>
              )
            })}
          </Slider>
          <CarouselControlsArrowsBasic2
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{ transform: 'translateY(-64px)', }}
          />
        </Box>
      }
      {/* <Button
        variant="outlined"
        color="inherit"
        size="large"
        endIcon={<Icon icon={roundArrowRightAlt} width={24} height={24} />}
        sx={{ mx: 'auto' }}
      >
        View all team members
      </Button> */}
    </Container>
  );
}
