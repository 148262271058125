// material
import { experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import { Box, Container, Divider, Stack, Tab, Tabs } from '@material-ui/core';
// components
import Page from '../../components/Page';
import { AboutHero, AboutWhat, AboutTeam, AboutVision, AboutTestimonials } from '../../components/_external-pages/about';
import MainNavbar from 'src/layouts/main/MainNavbar';
import HomeFirstSlider from './Home-page-first-slider/home-page-first-slider';
import MainFooter from 'src/layouts/main/MainFooter';
import HomePageTable from './Home-page-table/home-page-table';
import HomePageCategoryOne from './Home-page-Categories/home-page-category-one';
import { useState } from 'react';
import Maintenance from '../Maintenance';
import HomePageCategoryTwo from './Home-page-category-two/home-page-category-two';
import HomePageCategoryThree from './Home-page-category-three/home-page-category-three';
import HomePageCategoryFour from './Home-page-category-four/home-page-category-four';
import HomePageCategoryFive from './Home-page-category-five/home-page-category-five';
import { ShoppingCart } from '@material-ui/icons';
import HomePageNFTSlider from './Home-page-first-slider/home-page-NFT-slider';
import HomePageTableNFT from './Home-page-table-NFT/home-page-table-NFT';
import HomePageCategoryOneNFT from './Home-Page-Category-One-NFT/home-category-one-nft';
import HomePageCategoryTwoNFT from './Home-Page-Category-Two-NFT/home-category-two-nft';
import HomePageCategoryThreeNFT from './Home-Page-Category-Three-NFT/home-category-three-nft';
import HomePageCategoryFourNFT from './Home-Page-Category-Four-NFT/home-category-four-nft';
import HomePageCategoryFiveNFT from './Home-Page-Category-Five-NFT/home-category-five-nft';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(18)
  }
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  width: "fit-content",
  // marginRight: theme.spacing(0.5), 
  borderRadius: "10px", 
  // boxShadow: theme.shadows[5], 
  background: "transparent", 
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  minHeight: "fit-content",
  indicator: {
    display: "none",
  },
  height: 38,
  fontSize: 16,
  // border: "1.6px solid #0f171e",
  marginTop: 0,
  marginBottom:3,
  color: theme.palette.text.primary,
  "&:hover": {
    background: "rgba(255, 255, 255, 0.12)",
  },
  "&.Mui-selected": {
    background: 'rgba(255, 255, 255, 0.12)',
    color: "#fff",
  },
  "&:first-child": {
    borderTopLeftRadius: "7px",
    borderBottomLeftRadius: "7px",
  },
  "&:not(:first-child)": {
    marginLeft: theme.spacing(-3),
    marginBottom: theme.spacing(4), 
  },
  // Add text color
  "& .MuiTab-wrapper": {
    color: "inherit",
  },
  // Add font size for label
  "& .MuiTab-label": {
    fontSize: "16px", // Adjust the font size as needed
  },
}));

// ----------------------------------------------------------------------

const SCROLLABLE_TAB = [
  { value: '1', icon:<Stack className='simple-icons--blockchaindotcom' sx={{fontSize:'1.7rem',}} />, label: 'AI Model' },
  { value: '2', icon: <Stack className='ri--nft-fill' sx={{fontSize:'1.7rem',}} />, label: 'NFT' },
];

export default function HomeJoy() {
  const theme = useTheme();
  const [valueTab, setValueTab] = useState('one')
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const [valueTabSwitch, setValueTabSwitch] = useState('1')
  const handleChangeTabSwitch = (event, newValue) => {
    setValueTabSwitch(newValue);
  };
  const [valueTabNFT, setValueTabNFT] = useState('first')
  const handleChangeNFT = (event, newValue) => {
    setValueTabNFT(newValue);
  };
  return (
    <>
        
        <RootStyle title="Home Page | Joy Marketplace">
        <Container
        sx={{
          [theme.breakpoints.up('lg')]: {
            mt: -2, 
            mb:1.5,
            pl:0,
            pr:0
          },
          [theme.breakpoints.up('md')]: {
            mt: -2, 
            mb:1.5,
            pl:0,
            pr:0
          },
          [theme.breakpoints.up('sm')]: {
            mt: 2, 
            mb:1.5,
            pl:0,
            pr:0
          },
          [theme.breakpoints.up('xs')]: {
            mt: 2, 
            mb:1.5,
            pl:3,
            pr:4
          },
        }}
         maxWidth="lg">      
        
        <Stack direction={'row'} spacing={2}>
        <Tabs
            sx={{mb:5,pl:{xs:0,sm:6,md:6,lg:6},mt:{xs:0,sm:0,md:-4,lg:-4}}}
            allowScrollButtonsMobile
            value={valueTabSwitch}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChangeTabSwitch}
          >
            {SCROLLABLE_TAB.map((tab) => (
              <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs> 
          </Stack>
        {valueTabSwitch === '1' ?(<Stack direction={'row'} spacing={2}  >
          <Tabs
            value={valueTab}
            variant="scrollable"
            onChange={handleChange}
            className='css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons'
            textColor="secondary"
            indicatorColor="none"
            aria-label="secondary tabs example">
            <CustomTab value="one" label={'AI Models'} />
            <CustomTab value="2" label={'Customer Interaction models'} />
            <CustomTab value="3" label={'Image and Video Processing'} />
            <CustomTab value="4" label={'NLP AI models'} />
            <CustomTab value="5" label={'Predictive Analytics Models'} />
            <CustomTab value="6" label={'Voice Recognition Systems'} />
            <CustomTab value="8" label={'Personalization AI models'} />
            <CustomTab value="9" label={'AI-Driven Marketing Models'} />
            <CustomTab value="10" label={'Fraud Detection Models'} />
            <CustomTab value="11" label={'Supply Chain Optimization Models'} />
            <CustomTab value="12" label={'Environmental and Climate Models'} />
            <CustomTab value="13" label={'Robotics Control Models'} />
            <CustomTab value="14" label={'Educational AI Models'} />
            <CustomTab value="15" label={'Financial Planning Models'} />
            <CustomTab value="16" label={'Creative AI Models'} />
            <CustomTab value="17" label={'Machine Learning Techniques'} />
            <CustomTab value="18" label={'Quantum Machine Learning'} />
            <CustomTab value="19" label={'AI for Space Exploration'} />
            <CustomTab value="20" label={'Biotech and Genomic AI'} />
            <CustomTab value="21" label={'AI in Renewable Energy'} />
            <CustomTab value="22" label={'AI for Urban Planning'} />
            <CustomTab value="23" label={'Emotion Recognition Systems'} />
            <CustomTab value="24" label={'AI for Autonomous Vehicles'} />
            <CustomTab value="25" label={'Edge AI'} />
            <CustomTab value="26" label={'AI for Disaster Response'} />
            <CustomTab value="27" label={'AI in Agriculture'} />
            <CustomTab value="28" label={'Neuroscience and AI'} />
          </Tabs>
          </Stack>) : <Stack direction={'row'} spacing={2} sx={{
          [theme.breakpoints.up('lg')]: {
            mt: 0, 
            mb:1.5,
            pl:5,
            pr:0
          },
          [theme.breakpoints.up('md')]: {
            mt: 0, 
            mb:1.5,
            pl:5,
            pr:0
          },
          [theme.breakpoints.up('sm')]: {
            mt: 0, 
            mb:1.5,
            pl:1,
            pr:0
          },
          [theme.breakpoints.up('xs')]: {
            mt: 0, 
            mb:1.5,
            pl:1,
            pr:4
          },}}>
          <Tabs
            value={valueTabNFT}
            variant="scrollable"
            onChange={handleChangeNFT}
            className='css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons'
            textColor="secondary"
            indicatorColor="none"
            aria-label="secondary tabs example">
            <CustomTab value="first" label={'NFT'} />
            <CustomTab value="second" label={'Art'} />
            <CustomTab value="third" label={'Gaming'} />
            <CustomTab value="fourth" label={'Memberships'} />
            <CustomTab value="fifth" label={'PFPs'} />
            <CustomTab value="sixth" label={'Photography'} />
            <CustomTab value="seventh" label={'Music'} />
          </Tabs>
          </Stack>}
          </Container>
          {valueTabSwitch === '1' ?(<Box>
            {/* {valueTab === 'one' ? ( */}
            <Box>
            <HomeFirstSlider valueTab={valueTab} />
              <Box sx={{mb:4,mt:-4}}>
                <HomePageTable />
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryOne/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryTwo/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryThree/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryFour/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryFive/>
              </Box>
            </Box>
          {/* ) : <Maintenance/>} */}
          </Box>) : <Box>
            {/* {valueTabNFT === 'first' ? ( */}
            <Box>
            <HomePageNFTSlider valueTab={valueTabNFT}/>
              <Box sx={{mb:4,mt:-4}}>
                <HomePageTableNFT />
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryOneNFT/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryTwoNFT/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryThreeNFT/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryFourNFT/>
              </Box>
              <Box sx={{mt:3,}}>
                <HomePageCategoryFiveNFT/>
              </Box>
            </Box>
           {/* ) : <Maintenance/>} */}
          </Box>}
        </RootStyle>
        <MainFooter />
    </>
  );
}
{/* <AboutHero />
          <AboutWhat />
          <AboutVision />
          <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 2, height: 40 }} />
          <AboutTestimonials /> */}