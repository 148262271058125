import React, { useState, useEffect } from 'react';
import faker from 'faker';
import { sample } from 'lodash';
import PropTypes from 'prop-types';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Typography, Stack, Button, Divider, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { fCurrency } from '../../../utils/formatNumber';
import { mockImgProduct } from '../../../utils/mockImages';
import EmptyContent from 'src/components/EmptyContent';
import { LoadingButton } from '@material-ui/lab';
import { Delete, RemoveShoppingCart, Verified } from '@material-ui/icons';

const GENDER_OPTION = [
  { id: 1, label: 'Crypto', icon: 'cryptocurrency--adx' },
  { id: 2, label: 'Credit or debit card', icon: 'majesticons--creditcard-line' },
];

const PRODUCTS = [
  {
    id:1,
    name: 'Time Travel',
    image: 'https://i.pinimg.com/originals/52/91/4b/52914b8ac2f16a11c42786c3d89a84f5.gif',
    author: 'Wandemauts',
    earnings: 5,
    price: 0.0189,
    total: 0.0189,
    realMoney: 55.93,
  },
  {
    id:2,
    name: 'Time Loop',
    image: 'https://studio.pinotspalette.com/briercreek/images/ai-art-1024x683.jpg',
    author: 'Byte Art Brain',
    earnings: 9,
    price: 0.440,
    total: 0.440,
    realMoney: 55.93,
  },
];

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 68,
  height: 68,
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadiusSm
}));

function ProductItem({ product, onDelete }) {
  const { id, name, image, author, earnings, price } = product;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor: isHovered ? 'rgba(255, 255, 255, 0.12)' : 'transparent',
        backdropFilter: isHovered ? 'blur(8px)' : 'transparent',
        transition: 'background-color 0.3s ease',
        borderRadius: '7px',
        alignItems: 'center',
        padding: '12px',
      }}
    >
      <ThumbImgStyle alt={name} src={image} />
    
      <Box sx={{ flexGrow: 1, minWidth: 'fit-content' }}>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontSize: 'small',
              color: 'text.primary',
              typography: 'subtitle2',
              display: 'flex',
              mr: 1,
              alignItems: 'center', 
            }}
            style={{
              fontSize: '16px',
              maxWidth: 90,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
          <Verified color="primary" sx={{ fontSize: '15px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} />
        </Stack>
          
        <Stack direction="column">
          <Typography
            variant="body2"
            sx={{ color: 'text.primary', typography: 'subtitle2', fontSize: '13px' }}
          >
            {author}
          </Typography>
          
          <Typography
            variant="body2"
            sx={{ color: 'text.primary', typography: 'subtitle2', fontSize: '11px' }}
          >
            Creator Earnings: {earnings} %
          </Typography>
        </Stack>
      </Box>
          
      <Box>
        {isHovered && (
          <Button
            onClick={() => onDelete(id)}
            variant="text"
            color="error"
            sx={{ visibility: 'visible' }}
          >
            <RemoveShoppingCart />
          </Button>
        )}
        {!isHovered && (
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.primary', typography: 'subtitle2' }}
          >
            {price} ETH
          </Typography>
        )}
      </Box>
    </Stack>
  );
}


export default function HomePageCartItem() {
  const [paymentMethod, setPaymentMethod] = useState(GENDER_OPTION[0].label);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalRealMoney, setTotalRealMoney] = useState(0);
  const [products, setProducts] = useState(PRODUCTS);

  useEffect(() => {
    const newTotalPrice = products.reduce((acc, product) => acc + product.total, 0);
    setTotalPrice(newTotalPrice);

    const newTotalRealMoney = products.reduce((acc, product) => acc + product.realMoney, 0);
    setTotalRealMoney(newTotalRealMoney);

    setIsCartEmpty(products.length === 0);
  }, [products]);

  const handleDelete = (id) => {
    const updatedProducts = products.filter(product => product.id !== id);
    setProducts(updatedProducts);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleClearAll = () => {
    setProducts([]);
  };

  return (
    <>
      {!isCartEmpty && (
        <Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3, py: 2 }}>
            <Typography variant="subtitle1" sx={{ fontSize: '17px' }}>
              {products.length === 1 ? '1 item' : `${products.length} items`}
            </Typography>
            <Button size="small" color="error" onClick={handleClearAll}>
              Clear all
            </Button>
          </Stack>
          <Stack spacing={3} sx={{ pl: 1.5, pr: 1.5 }}>
            {products.map((product, index) => (
              <ProductItem key={product.id} product={product} onDelete={handleDelete} />
            ))}
          </Stack>
          <Divider sx={{ mt: 3.5 }} />
          <Stack direction="row" spacing={2} sx={{ pt: 2, pl: 3, pr: 2 }}>
            <Box sx={{ flexGrow: 1, minWidth: 200 }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', typography: 'subtitle2', fontSize: '17px' }}>
                Total Price
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', typography: 'subtitle2' }}>
                {totalPrice} ETH
              </Typography>
              <Stack direction="row">
                <Typography variant="body2" sx={{ color: 'text.secondary', typography: 'subtitle2' }}>
                  $ {totalRealMoney}
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ pt: 2.5, pl: 3, pr: 3 }}>
            <Box sx={{ flexGrow: 1, minWidth: 200 }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', typography: 'subtitle2', fontSize: '17px' }}>
                Payment Method
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', typography: 'subtitle2' }} style={{ maxWidth: 104, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {paymentMethod}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="column" spacing={2} sx={{ pt: 2.5, pl: 3, pr: 3 }}>
            <Box sx={{ mt: -2 }}>
              <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
                <Stack>
                  {GENDER_OPTION.map((gender) => (
                    <FormControlLabel
                      key={gender.id}
                      value={gender.label}
                      control={<Radio />}
                      label={
                        <Stack direction="row" alignItems="center">
                          <Box className={gender.icon} sx={{ mr: 1, mt: 0, fontSize: '1.5rem' }}>

                          </Box>
                          {gender.label}
                        </Stack>
                      }
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
            <LoadingButton type="submit" variant="contained">
              {'Complete Purchase'}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
      {isCartEmpty && (
        <EmptyContent
          title="Cart is empty"
          description="Looks like you have no items in your shopping cart."
          img="/static/illustrations/illustration_empty_cart.svg"
        />
      )}
    </>
  );
}