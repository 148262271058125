import faker from 'faker';
import { useEffect, useRef,useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import roundArrowRightAlt from '@iconify/icons-ic/round-arrow-right-alt';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
// material
import { alpha, useTheme } from '@material-ui/core/styles';
import { Box, Card, Button, Container, Typography, IconButton, Stack, Tabs, Tab } from '@material-ui/core';
//


import { varFadeIn, varFadeInUp, MotionInView, varFadeInDown } from 'src/components/animate';
import { CarouselControlsArrowsBasic2 } from 'src/components/carousel';
import { styled } from '@material-ui/styles';
import { Verified, ViewList, ViewModule } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { PATH_PAGE } from 'src/routes/paths';
import { collectionsAddress,NFTmodelsAddress, AImodelsAddress, REST_API } from 'src/constants/DefaultConstantvalues';
import { ethers } from 'ethers';
import DeployAIModel from 'src/artifacts/contracts/DeployAIModel.sol/DeployAIModel.json';
import DeployNFTModel from 'src/artifacts/contracts/DeployNFTModel.sol/DeployNFTModel.json';
import Collections from 'src/artifacts/contracts/Collections.sol/Collections.json';
import AIModels from 'src/artifacts/contracts/AIModels.sol/AIModels.json';
import NFTModels from 'src/artifacts/contracts/NFTModels.sol/NFTModels.json';
import { MCircularProgress } from 'src/components/@material-extend';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

// const CustomTab = styled(Tab)(({ theme }) => ({
//   width: "fit-content",
//   // marginRight: theme.spacing(0.5), 
//   borderRadius: "10px", 
//   // boxShadow: theme.shadows[5], 
//   background: "transparent", 
//   paddingLeft: theme.spacing(3),
//   paddingRight: theme.spacing(3),
//   paddingTop: theme.spacing(1),
//   paddingBottom: theme.spacing(1),
//   minHeight: "fit-content",
//   indicator: {
//     display: "none",
//   },
//   height: 38,
//   fontSize: 16,
//   // border: "1.6px solid #0f171e",
//   marginTop: 0,
//   color: theme.palette.text.primary,
//   "&:hover": {
//     background: "rgba(255, 255, 255, 0.12)",
//   },
//   "&.Mui-selected": {
//     background: 'rgba(255, 255, 255, 0.12)',
//     color: "#fff",
//   },
//   "&:first-child": {
//     borderTopLeftRadius: "7px",
//     borderBottomLeftRadius: "7px",
//   },
//   "&:not(:first-child)": {
//     marginLeft: theme.spacing(-3),
//     marginBottom: theme.spacing(4), 
//   },
//   // Add text color
//   "& .MuiTab-wrapper": {
//     color: "inherit",
//   },
//   // Add font size for label
//   "& .MuiTab-label": {
//     fontSize: "16px", // Adjust the font size as needed
//   },
// }));


const ContentItemStyle = styled('div')(({ theme }) => ({
    bottom: 0,
    zIndex: 9,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottomLeftRadius: 10,
    // backdropFilter: 'blur(8px)',
    // WebkitBackdropFilter: 'blur(8px)', // Fix on Mobile
    borderBottomRightRadius: 10,
    justifyContent: 'space-between',
    backgroundColor: alpha(theme.palette.grey[900], 0.50),
    flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row'
  }));

const MEMBERS1 = [
  {
    role: 'Goku NFT',
    avatar: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcGI4MWh0ZXY1Yzk4Y2c5MGxweTkyNDNwZjJubnYzNncyZ3oxd281diZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ov9k1x0lY0sAeOc8g/giphy.gif',
    floor:'0.45 ETH'
  },
  {
    role: 'Joe Don',
    avatar: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdHI1YXppa3VldnNkaGthdjFhODN2OWN0aHZ3cnJ4OG84MGFxNWY0bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3OzQi2PoO16QG8zjg6/giphy.gif',
    floor:'0.36 ETH'
  },
  {
    role: 'Dig Forest',
    avatar: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzhscTZjdmtianE3MmNmd2gyaXJ5MXEzZG40YTBqdDUzaHlscm5hNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/9MJ65OWvrNIXnOiNEk/giphy-downsized-large.gif',
    floor:'0.30 ETH'
  },
  {
    role: 'Photo Laboratary',
    avatar: 'https://miro.medium.com/v2/resize:fit:679/0*f9iPuv2fhOgAMyQn.gif',
    floor:'0.75 ETH'
  },
  {
    role: 'Date Here',
    avatar: 'https://cdn.pixabay.com/animation/2023/05/02/15/28/15-28-22-399_512.gif',
    floor:'0.47 ETH'
					 
  },
  {
    role: 'Quality',
    avatar: 'https://images.ctfassets.net/nccdc912q1to/1VYtqnlliiDxMxPiHjSC1q/137f76e8314d36a29a3e7ecf881c38c6/bear-2.gif',
    floor:'0.60 ETH'		
  }
];

// ----------------------------------------------------------------------

MemberCard.propTypes = {
  member: PropTypes.object
};

const MediaComponent = ({ filePath, mimeType }) => {
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Box sx={{ width: '100%', borderTopRightRadius: 1.5, borderTopLeftRadius: 1.5, height: '190px', overflow: 'hidden' }}>
      {filePath && mimeType?.startsWith('video') ? ( // && filePath.includes('video')
        <video
          src={filePath}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          // controls
          loop
          muted
          autoPlay={isPlaying}
        />
      ) : (
        <img
          src={filePath}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt="media"
        />
      )}
    </Box>
  );
};


function MemberCard({ member }) {
  const { name, role, avatar,floor,aiModelimages,blockchain,category,collectionName, description, price, supply, tokenSymbol} = member;
  const navigate = useNavigate();
  function handleInnerPage(member){
    navigate(PATH_PAGE.buyItemNFT,{state:{memberData:member}})
  }

  return (
    <Card key={name} sx={{ mx: 1.5, boxShadow:'none'}} onClick={()=>handleInnerPage(member)}>
      {/* <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        {role}
      </Typography> */}
      {/* <Box component="img" src={avatar} sx={{ width: '100%', borderRadius: 1.5 }} /> */}
      <Box sx={{ position: 'relative', zIndex: 0 }}>
        {/* <Box component="img" src={aiModelimages?.length>0 ? REST_API + aiModelimages[0]?.filePath : avatar} sx={{ width: '100%',height: 350, borderRadius: 1.5, objectFit:'cover' }} /> */}
        <div>
          <MediaComponent filePath={aiModelimages[0]?.filePath} mimeType={aiModelimages[0]?.mimeType} />
        </div>
          <ContentItemStyle>

            <Stack direction="column" alignItems="flex-start" sx={{ml:1}}>
                <Stack direction="row">
                    <Typography variant="h6" sx={{ color: 'common.white', ml: 0 }} onClick={()=>handleInnerPage(member)}>
                        {name}
                    </Typography>
                    <Verified sx={{ color: 'primary.main',ml:1,fontSize: '1rem',mt:0.6 }}/>
                </Stack>
                <Typography variant="body2" sx={{ color: 'common.white', }}>
                    Floor: {price ? price : 0} ETH
                </Typography>
            </Stack>
            
          </ContentItemStyle>
        </Box>
      {/* <Box sx={{ mt: 2, mb: 1 }}>
        {[facebookFill, instagramFilled, linkedinFill, twitterFill].map((social, index) => (
          <IconButton key={index}>
            <Icon icon={social} width={20} height={20} />
          </IconButton>
        ))}
      </Box> */}
    </Card>
  );
}

export default function HomePageNFTSlider({valueTab}) {
  const carouselRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false)
  const [MEMBERS,setMEMBERS] = useState([])
  const [category,setCategory] = useState('NFT')

  const getAIModels = async () => {
    setIsLoading(true);
    try {
      if (!window.ethereum) {
        enqueueSnackbar('Please install MetaMask', { variant: 'error' });
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []); // Request account access if needed
      const signer = provider.getSigner();
      console.log('NFTmodelsAddress', NFTmodelsAddress);
      const AIModelsContract = new ethers.Contract(NFTmodelsAddress, NFTModels.abi, signer);
      const getAllAIModelsResult = await AIModelsContract.getAllAIModels();
      console.log('getAllAIModelsResult--', getAllAIModelsResult);
  
      // Fetch model data in parallel
      const modelDataPromises = getAllAIModelsResult.map(async (aiModeldata) => {
        const aiModelContract = new ethers.Contract(aiModeldata.contractAddress, DeployNFTModel.abi, signer);
        const aiModelDatas = await aiModelContract.getContract();
        const earnings = await aiModelContract.getCreatorEarnings(aiModeldata.id);
        const tokenOwners = await aiModelContract.getTokenOwners(aiModeldata.id);
        
        return {
          collectionName: aiModelDatas[0],
          blockchain: aiModelDatas[1],
          tokenSymbol: aiModelDatas[2],
          name: aiModelDatas[3],
          buyers: aiModelDatas[8],
          supply: parseInt(aiModeldata.supply),
          price: parseFloat(ethers.utils.formatUnits(aiModeldata.price, 'ether')),
          tokenOwners: tokenOwners,
          contractAddress: aiModeldata.contractAddress,
          category: aiModeldata.category,
          description: aiModeldata.description,
          link: aiModeldata.link,
          tabs: aiModeldata.additionalTabs,
          ownerEmail: aiModeldata.ownerEmail,
          owner: aiModeldata.owner,
          startDate: aiModeldata.startDate,
          endDate: aiModeldata.endDate,
          status: aiModeldata.status,
          id: aiModeldata.id,
          aiModelimages: aiModeldata.aiModelimages,
          creatorEarnings: ethers.utils.formatEther(earnings)
        };
      });
  
      const allModelsData = await Promise.all(modelDataPromises);
      console.log('allModelsData--', allModelsData);
  
      const filterByCategory = (models, category) => models.filter(item => item.category === category);
  
      let filteredModels;
      switch (valueTab) {
        case 'second':
          filteredModels = filterByCategory(allModelsData, 'Art');
          break;
        case 'third':
          filteredModels = filterByCategory(allModelsData, 'Gaming');
          break;
        case 'fourth':
          filteredModels = filterByCategory(allModelsData, 'Memberships');
          break;
        case 'fifth':
          filteredModels = filterByCategory(allModelsData, 'PFPs');
          break;
        case 'sixth':
          filteredModels = filterByCategory(allModelsData, 'Photography');
          break;
        case 'seventh':
          filteredModels = filterByCategory(allModelsData, 'Music');
          break;
        default:
          filteredModels = allModelsData;
      }
  
      console.log('filteredModels---', filteredModels);
      setMEMBERS(filteredModels);
    } catch (error) {
      console.log('getAllAIModels Error : ', error);
      setMEMBERS([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    getAIModels();
  }, [valueTab]);
  
  
  // const handleChange = (event, newValue) => {
  // 	setValueTab(newValue);
  // };

  const settings = {
    speed: 500,
    slidesToShow: MEMBERS?.length < 4 ? MEMBERS?.length : 4,
    centerMode: true,
    background:'#161c24',
    centerPadding: '0 10px',
    rtl: Boolean(theme.direction === 'rtl'),
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,  
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: MEMBERS?.length < 3 ? MEMBERS?.length : 3 }
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: MEMBERS?.length < 2 ? MEMBERS?.length : 2 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 }
      }
    ]
  };
  

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 10, textAlign: 'center' }}>
      {/* <MotionInView variants={varFadeInDown}>
        <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
          Dream team
        </Typography>
      </MotionInView> */}

      {/* <MotionInView variants={varFadeInUp}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Great team is the key
        </Typography>
      </MotionInView>

      <MotionInView variants={varFadeInUp}>
        <Typography
          sx={{
            mb: 10,
            mx: 'auto',
            maxWidth: 630,
            color: (theme) => (theme.palette.mode === 'light' ? 'text.secondary' : 'common.white')
          }}
        >
          Minimal will provide you support if you have any problems, our support team will reply within a day and we
          also have detailed documentation.
        </Typography>
      </MotionInView> */}
      {/* <Stack direction={'row'} spacing={2}>
      <Tabs
        sx={{
          [theme.breakpoints.up('lg')]: {
            mt: -6, 
          },
          [theme.breakpoints.up('md')]: {
            mt: -6, 
          },
          [theme.breakpoints.up('sm')]: {
            mt: 0, 
          },
          [theme.breakpoints.up('xs')]: {
            mt: 0, 
          },
        }}
        value={valueTab}
        variant="scrollable"
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="none"
        aria-label="secondary tabs example">
        <CustomTab value="one" label={'All'} />
        <CustomTab value="two" label={'Art'} />
        <CustomTab value="three" label={'Gaming'} />
        <CustomTab value="four" label={'Memberships'} />
        <CustomTab value="five" label={'PFPs'} />
        <CustomTab value="six" label={'Photography'} />
        <CustomTab value="seven" label={'Music'} />
      </Tabs>
      </Stack> */}
      {isLoading ?
        <Stack direction={{ xs: 'row', sm: 'row' }} >
          <MCircularProgress sx={{ color: '#fff' }} size={24} /> 
          <Typography variant='body1' sx={{mb:1.5,ml:2}}>Loading {category}...</Typography>
        </Stack>
        :
      <Box sx={{ position: 'relative',cursor:'pointer' }}>
        <Slider ref={carouselRef} {...settings}>
          {MEMBERS?.map((member,index) => (
					 
            <MotionInView key={member.contractAddress} variants={varFadeIn}>
              <MemberCard key={member.contractAddress} member={member} />
            </MotionInView>
          ))}
			   
        </Slider>
        <CarouselControlsArrowsBasic2
          onNext={handleNext}
          onPrevious={handlePrevious}
          sx={{ transform: 'translateY(-64px)', }}
        />
      </Box>
      }
      {/* <Button
        variant="outlined"
        color="inherit"
        size="large"
        endIcon={<Icon icon={roundArrowRightAlt} width={24} height={24} />}
        sx={{ mx: 'auto' }}
      >
        View all team members
      </Button> */}
    </Container>
  );
}
