import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import {
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  Divider,
  Box,
  Link,
  Button,
  Avatar,
} from '@material-ui/core';
import fakeRequest from '../../../utils/fakeRequest';
import { fShortenNumber } from 'src/utils/formatNumber';
import { orderBy } from 'lodash';
// import sgMail from '@sendgrid/mail'
import { rfqEmail } from 'src/theme/emailTemplates';
import axios from 'axios';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';

// Importing necessary icons
import { ArrowForward, CheckRounded } from '@material-ui/icons';

// Importing verification code form
import { VerifyCodeForm } from 'src/components/authentication/verify-code';
import { REST_API } from 'src/constants/DefaultConstantvalues';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  divider: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&::before, &::after': {
      content: '""',
      flex: '1',
    },
    '&::before': {
      marginRight: '0.5em',
    },
    '&::after': {
      marginLeft: '0.5em',
    },
  },
}));

// Mock data for wallet providers
const displayAuthor = [
  {
    id: 1,
    name: 'MetaMask',
    avatar: 'https://opensea.io/static/images/logos/metamask-fox.svg',
  },
  // You can add more wallet providers here
];

AuthorItem.propTypes = {
  author: PropTypes.object,
  index: PropTypes.number,
};

function AuthorItem({ author, index }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
      <Avatar alt={author.name} src={author.avatar} />
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{author.name}</Typography>
      </Box>
    </Stack>
  );
}

export default function JoyLoginForm({
  emailEntered,
  setEmailEntered,
  setVerificationCode,
  verificationCode,
  setWelcome,
  welcome,
  handleCloseLogin,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [submittedName, setSubmittedName] = useState('');
  const [sentOTP, setSentOTP] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();

  function handleWelcomeCancel() {
    setWelcome(false);
  }

  function handleWelcome() {
    enqueueSnackbar('Welcome To Joy Marketplace', { variant: 'success' });
    handleCloseLogin();
  }

  const NewProductSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    name: Yup.string()
      .required('Name is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: submittedEmail ? submittedEmail : '',
      name: submittedName ? submittedName : '',
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await axios.post(`${REST_API}/auth/webservice/login`, values)
        .then(res=>{
          if(res.data.status === 1){
            setSentOTP(res.data.otp)
            enqueueSnackbar(`OTP send to ${values?.email}`, { variant: 'success' });
            resetForm();
            setSubmitting(false);
            setVerificationCode(true);
            setSubmittedEmail(values.email);
            setSubmittedName(values.name);
          }else if(res.data.status === 0){
            enqueueSnackbar('Please wait for 10 minutes before requesting a new OTP', { variant: 'error' });
          }else{
            enqueueSnackbar('Failed to send OTP. Please retry', { variant: 'error' });
          }
        })
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
    
  });
  
  function handleEmailRendered() {
    if (values.email === 'admin@joy.com') {
      navigate(PATH_DASHBOARD.root);
      enqueueSnackbar('Logging in...', { variant: 'info' });
      
      login(values.email)
        .then(() => {
          enqueueSnackbar('Admin logged in successfully', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.error('Login failed:', error);
          enqueueSnackbar('Login failed. Please try again.', {
            variant: 'error',
          });
        });
    } else {
      if (formik.isValid) {
        setEmailEntered(true);
      } else {
        enqueueSnackbar('Please enter a valid email address', {
          variant: 'error',
        });
      }
    }
  }
  

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  function handleEmailRendered() {
    NewProductSchema.validate({ email: values.email,name:values.name })
      .then(() => {
        setEmailEntered(true);
      })
      .catch(() => {
        setEmailEntered(false);
      });
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          {!verificationCode && (
            <Grid container>
              <Grid item xs={12} md={12}>
                <Stack spacing={3}>
                  <Stack mt={2} gap={2}>
                    <TextField
                      fullWidth
                      label={'Name'}
                      disabled={emailEntered}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      fullWidth
                      label={!emailEntered && 'Continue With Mail'}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      disabled={emailEntered}
                      type="email"
                      InputProps={{
                        endAdornment: (
                          <>
                            {!emailEntered ? (
                              <LoadingButton
                                loadingIndicator="Loading..."
                                className="css-wzg810-MuiButtonBase-root-MuiButton-root1"
                                variant="contained"
                                onClick={handleEmailRendered}
                              >
                                <ArrowForward sx={{ fontSize: '1.2rem' }} />
                              </LoadingButton>
                            ) : (
                              <Box className="material-symbols--check-circle-rounded" sx={{ fontSize: '2.3rem', color: '#fe3499' }} />
                            )}
                          </>
                        ),
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleEmailRendered();
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
          {emailEntered && !verificationCode && (
            <>
              <Typography variant="body1" sx={{ fontSize: '13px', mt: 3, display: 'flex', justifyContent: 'center' }} color="textSecondary">
                Signing up will create a new wallet using this email.
              </Typography>
              <LoadingButton sx={{ mt: 3, p: 1.2 }} loading={isSubmitting} loadingIndicator="Loading..." fullWidth type="submit" variant="contained">
                {'Sign up and Create Wallet'}
              </LoadingButton>
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                By continuing you agree to our&nbsp;
                <br />
                <Link underline="always" sx={{ color: 'text.primary', cursor: 'pointer' }}>
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" sx={{ color: 'text.primary', cursor: 'pointer' }}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </>
          )}

          {/* {!emailEntered && !verificationCode && (
            <>
              <Divider sx={{ mt: 3 }} className={classes.divider}>
                <Typography variant="body1" sx={{ fontSize: '13px' }} color="textSecondary">
                  OR
                </Typography>
              </Divider>
              <Card sx={{ mt: 3, p: 2, boxShadow: 'none', border: '1px solid #45505c' }}>
                <Stack>
                  {displayAuthor.map((author, index) => (
                    <AuthorItem key={author.id} author={author} index={index} />
                  ))}
                </Stack>
              </Card>
            </>
          )} */}
          {verificationCode && !welcome && (
            <Box sx={{ mt: 5, mb: 3 }}>
              <VerifyCodeForm submittedEmail={submittedEmail} submittedName={submittedName} setWelcome={setWelcome} sentOTP={sentOTP} setVerificationCode={setVerificationCode} verificationCode={verificationCode} />
            </Box>
          )}
          {welcome && (
            <Box sx={{ mt: 5, mb: 3 }}>
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                By connecting your wallet using Joy Marketplace, you agree to our&nbsp;
                <br />
                <Link underline="always" sx={{ color: 'text.primary', cursor: 'pointer' }}>
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" sx={{ color: 'text.primary', cursor: 'pointer' }}>
                  Privacy Policy
                </Link>
                .
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, gap: 1 }}>
                <Button sx={{ p: 1 }} fullWidth variant="outlined" onClick={handleWelcomeCancel}>
                  {'Cancel'}
                </Button>
                <Button sx={{ p: 1 }} fullWidth variant="contained" onClick={handleWelcome}>
                  {'Accept and sign'}
                </Button>
              </Box>
            </Box>
          )}
        </Form>
      </FormikProvider>
    </>
  );
}
