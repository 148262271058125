import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { OutlinedInput, FormHelperText, Stack, Typography, Link } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// utils
import fakeRequest from '../../../utils/fakeRequest';
import { useEffect, useRef, useState } from 'react';
import localStorage from 'redux-persist/es/storage';
import { REST_API } from 'src/constants/DefaultConstantvalues';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function VerifyCodeForm({ submittedEmail, submittedName, setWelcome, sentOTP, setVerificationCode, verificationCode }) {
  const [countdown, setCountdown] = useState(10 * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.number().required('Code is required'),
    code2: Yup.number().required('Code is required'),
    code3: Yup.number().required('Code is required'),
    code4: Yup.number().required('Code is required'),
    code5: Yup.number().required('Code is required'),
    code6: Yup.number().required('Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { resetForm }) => {
      await fakeRequest(500);
      enqueueSnackbar('Verify success', { variant: 'success' });
      resetForm();
      setWelcome(true);
    },
  });

  const handleWelcomeOpen = async () => {
    const cleanSentOTP = sentOTP.replace(/\s/g, '');
    const enteredOTP = Object.values(formik.values).join('').replace(/\s/g, '');
    if (enteredOTP === cleanSentOTP) {
      console.log('OTP matched successfully');
      enqueueSnackbar('Verify success', { variant: 'success' });
      setWelcome(true);

      const response1 = await axios.post(`${REST_API}/auth/webservice/profile-get-by-email`, {
        email: submittedEmail
      });
      console.log("response1.data---", response1.data);
      if (response1.data.status === 1) {
        const customerData = response1.data.customerData;
        localStorage.setItem('userData', JSON.stringify(customerData));
        localStorage.setItem('userId', customerData?.id);
        localStorage.setItem('isLoggedIn', true)
        localStorage.setItem('loggedInEmail', submittedEmail)
        navigate('/');
        window.location.reload()
        return;
      } else {
        const response = await axios.post(`${REST_API}/auth/webservice/profile-create/`, { email: submittedEmail, name: submittedName });
        console.log('Response:', response.data);
        if (response.data.status === 1) {
          const VendorId = response.data.VendorId;
          const customerData = response.data.customerData;
          localStorage.setItem('userData', JSON.stringify(customerData));
          localStorage.setItem('userId', VendorId);
          localStorage.setItem('isLoggedIn', true)
          localStorage.setItem('loggedInEmail', submittedEmail)
          enqueueSnackbar('Profile created successfully', { variant: 'success' });
          navigate('/');
          window.location.reload()
        } else {
          enqueueSnackbar('Failed to create profile.', { variant: 'error' });
        }
      }
    } else {
      console.log('OTP did not match');
      enqueueSnackbar('Incorrect OTP, please try again', { variant: 'error' });
    }
  }

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData('text').replace(/\D/g, '');
    const formattedValue = pastedValue.slice(0, 6);
    const fieldValues = formattedValue.split('').reduce((acc, char, index) => {
      acc[`code${index + 1}`] = char;
      return acc;
    }, {});
    formik.setValues({ ...formik.values, ...fieldValues });
  };

  const inputRefs = {
    code1: useRef(),
    code2: useRef(),
    code3: useRef(),
    code4: useRef(),
    code5: useRef(),
    code6: useRef(),
  };

  const handleKeyUp = (e, fieldName) => {
    const index = parseInt(fieldName.replace('code', ''), 10);
    if (e.target.value && index < 6) {
      inputRefs[`code${index + 1}`].current.focus();
    }
  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length <= 1) { // Ensure only one character is accepted
      formik.setFieldValue(fieldName, value);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(values).map((item) => (
            <OutlinedInput
              key={item}
              {...getFieldProps(item)}
              type="text"
              placeholder="-"
              value={values[item]}
              onChange={(e) => handleChange(e, item)}
              error={Boolean(touched[item] && errors[item])}
              inputProps={{
                maxLength: 1,
                sx: {
                  p: 0,
                  textAlign: 'center',
                  width: { xs: 36, sm: 56 },
                  height: { xs: 36, sm: 56 },
                },
                onPaste: handlePaste,
                ref: inputRefs[item],
                onKeyUp: (e) => handleKeyUp(e, item),
              }}
            />
          ))}
        </Stack>

        <FormHelperText error={!formik.isValid && formik.submitCount > 0} style={{ textAlign: 'right' }}>
          {!formik.isValid && formik.submitCount > 0 && 'Please enter all code digits'}
        </FormHelperText>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 3, display: 'flex', justifyContent: 'center' }} color="textSecondary">
          A one-time authentication code has been sent to
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 0.5, display: 'flex', justifyContent: 'center', fontWeight: 'bold' }} color="textSecondary">
          {submittedEmail}
        </Typography>
        <LoadingButton fullWidth size="large" variant="contained" onClick={handleWelcomeOpen} sx={{ mt: 3 }} disabled={!formik.isValid}>
          Verify
        </LoadingButton>
        <Typography variant="body1" sx={{ fontSize: '13px', mt: 2.7, display: 'flex', justifyContent: 'center' }} color="textSecondary">
          Expires in
          <Typography variant="body1" sx={{ fontSize: '13px', ml: 0.5 }} color="textSecondary">{minutes}:{seconds < 10 ? '0' : ''}{seconds}</Typography>
          <Link variant="body1" onClick={() => setVerificationCode(false)} sx={{ fontSize: '13px', ml: 0.5, cursor: 'pointer' }} color="info.main">Resend code</Link>
        </Typography>
      </Form>
    </FormikProvider>
  );
}
