import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { Icon } from '@iconify/react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack, Typography, ListItem, ListItemText, ListItemIcon, Divider, Paper } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../../components/@material-extend';
//
import MenuPopover from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import { useEffect, useRef, useState } from 'react';
import HomePageCartItem from 'src/pages/Joy-home-page/Home-page-cart-item-list/home-page-cart-item-list';
import JoyLoginPopUp from 'src/pages/Joy-home-page/Joy-login-popup/joy-login-popup';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import Searchbar from '../dashboard/Searchbar';
import { AccountCircle, Logout } from '@material-ui/icons';
import localStorage from 'redux-persist/es/storage';
import { useSnackbar } from 'notistack';
import HomeJoy from 'src/pages/Joy-home-page/joy-home-page';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;
const ITEM_HEIGHT = 'fit-content';
const PADDING_ITEM = 1.5;
const DRAWER_WIDTH = 350;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const menuItems = [
  { id: 1, icon: <Box sx={{ fontSize: '24px' }} className="simple-icons--blockchaindotcom" />, text: "Create AI Model" },
  { id: 2, icon: <Box sx={{ fontSize: '24px' }} className="ri--nft-fill" />, text: "Create NFT" },
];

export default function NavbarNew() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const isHome = pathname === '/';
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [walletInfo, setWalletInfo] = useState({ eth: 0, matic: 0 });

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await localStorage.getItem('isLoggedIn');
      setIsLoggedIn(loggedIn);

      // Mock wallet information, replace with actual wallet info retrieval logic
      if (loggedIn) {
        setWalletInfo({ eth: 0, matic: 0 });
      }
    };
    checkLoginStatus();
  }, []);

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenProfile = async () => {
    const loggedIn = await localStorage.getItem('isLoggedIn')
    console.log('loggedIn----------',loggedIn);
    
    if(loggedIn){
      navigate(PATH_PAGE.profileUser)
    }else{
      enqueueSnackbar('Please login', { variant: 'error' });
    }
  };

  const handleLogout = async () => {
    await localStorage.removeItem('isLoggedIn');
    await localStorage.removeItem('loggedInEmail');
    await localStorage.removeItem('userId');
    await localStorage.removeItem('userData');
    setIsLoggedIn(false);
    // window.location.reload();
    navigate('/');
    // <HomeJoy />
  }

  const handleOpen = async() => {
    
    const loggedIn = await localStorage.getItem('isLoggedIn')
    console.log('loggedIn----------',loggedIn);
    
    if(loggedIn){
      setOpen(true);
    }else{
      enqueueSnackbar('Please login', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (openCart) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openCart]);

  const handleToggle = async() => {
    
    const loggedIn = await localStorage.getItem('isLoggedIn')
    console.log('loggedIn----------',loggedIn);
    
    if(loggedIn){
      setOpenCart((prev) => !prev);
    }else{
      enqueueSnackbar('Please login', { variant: 'error' });
    }
  };

  const handleCartClose = () => {
    setOpenCart(false);
  };

  return (
    <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0, backdropFilter: 'blur(20px)' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo sx={{ mr: { xs: 1.5, md: 0 } }} />
          </RouterLink>
          <Typography variant='h4' color="common.white" sx={{ ml: 1.4, mr: 2, display: { md: 'block', sm: 'none', xs: 'none' } }}>
            Joy Marketplace
          </Typography>
          <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 5, height: 40, ml: 0, display: { md: 'block', sm: 'none', xs: 'none' } }} />
          <Box sx={{ flexGrow: 1 }} />
          {/* <Box sx={{ mr: 2, display: { xs: 'none', md: 'block' } }}>
            <Searchbar />
          </Box> */}

          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            {!isLoggedIn ? (
              <Button
                onClick={handleClickOpenLogin}
                sx={{
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', sm: 'transparent', xs: 'rgba(255, 255, 255, 0.12)' },
                  border: { md: 'transparent', sm: '1px solid rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', sm: 'transparent', xs: 'rgba(255, 255, 255, 0.12)' },
                  },
                  borderRadius: '12px',
                  fontSize: '16px',
                  height: '48px',
                  minWidth: '48px',
                  position: 'relative',
                  padding: '0px 12px',
                  backdropFilter: 'blur(20px)',
                  transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                  display: 'flex',
                  alignItems: 'center',
                }}
                startIcon={<Box className='material-symbols--wallet' />}
              >
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}>Login</Box>
              </Button>
            ) : ''
            // (
            //   <Box sx={{ display: { xs: 'block', sm: 'block' }, gap: 1 }}>
            //     <Button
            //       sx={{
            //         backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
            //         color: '#fff',
            //         '&:hover': {
            //           backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
            //         },
            //         borderRadius: '12px',
            //         height: '48px',
            //         minWidth: '48px',
            //         position: 'relative',
            //         padding: '0px 12px',
            //         backdropFilter: 'blur(20px)',
            //         transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
            //         alignItems: 'center',
            //         justifyContent: 'center',
            //       }}
            //     >
            //       <Box sx={{ mt: 0.5, mr: 1 }}>
            //         <Box className='material-symbols--wallet' sx={{ fontSize: '1.5rem' }} />
            //       </Box>
            //       <Typography>{walletInfo.eth} ETH</Typography>
            //       <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 5, height: 23, ml: 1, mr: 1.4, display: { md: 'block', sm: 'block', xs: 'block' }, }} />
            //       <Typography>{walletInfo.matic} MATIC</Typography>
            //     </Button>
            //   </Box>
            // )
            }

            <Button
              sx={{
                backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                color: '#fff',
                '&:hover': {
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                },
                borderRadius: '12px',
                height: '48px',
                minWidth: '48px',
                position: 'relative',
                padding: '0px 12px',
                backdropFilter: 'blur(20px)',
                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleOpenProfile}
            >
              <AccountCircle sx={{ fontSize: '24px' }} />
            </Button>

            {/* <Button
              sx={{
                backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                color: '#fff',
                '&:hover': {
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                },
                borderRadius: '12px',
                height: '48px',
                minWidth: '48px',
                position: 'relative',
                padding: '0px 12px',
                backdropFilter: 'blur(20px)',
                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleToggle}
            >
              <Box sx={{ fontSize: '24px' }} className='mdi--cart-outline' />
            </Button> */}

            <Button
              sx={{
                backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                color: '#fff',
                '&:hover': {
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                },
                borderRadius: '12px',
                fontSize: '16px',
                height: '48px',
                minWidth: '48px',
                position: 'relative',
                padding: '0px 12px',
                backdropFilter: 'blur(20px)',
                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                border: { md: 'transparent', sm: '1px solid rgba(255, 255, 255, 0.12)', xs: '1px solid rgba(255, 255, 255, 0.12)' },
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleOpen}
              ref={anchorRef}
            >
              <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' }, mr: 0.9 }}>Create Item</Box>
              <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' }, mr: { xs: 0, sm: 0.9, md: 0.9 } }}>Create</Box>
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none' }, fontSize: { xs: '0px', sm: '18px', md: '18px' }, }} className='simple-icons--blockchaindotcom' />
            </Button>
            {isLoggedIn ?
            <Button
              sx={{
                backgroundColor: { md: 'rgba(255, 255, 255, 0.12)', xs: 'transparent' },
                color: '#ff0000',
                '&:hover': {
                  backgroundColor: { md: 'rgba(255, 255, 255, 0.3)', xs: 'transparent' },
                },
                borderRadius: '12px',
                height: '48px',
                minWidth: '48px',
                position: 'relative',
                padding: '0px 12px',
                backdropFilter: 'blur(20px)',
                transition: 'background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1) 0s',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleLogout}
            >
              <Logout sx={{ fontSize: '24px' }} />
            </Button> : ''}
          </Stack>

          <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 260 }}>
            <Scrollbar sx={{ height: ITEM_HEIGHT * 8, paddingLeft: 2, paddingRight: 2 }}>
              {menuItems.map((item) => (
                <ListItem onClick={() => navigate(item.id === 1 ? PATH_PAGE.createItem : PATH_PAGE.createItemNFT)} key={item.id} button disableGutters sx={{ px: PADDING_ITEM, height: ITEM_HEIGHT, mt: 1, mb: 1, borderRadius: '10px' }}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ typography: 'subtitle2', mb: 0.5, fontSize: '14px', mt: 0.5 }}
                    secondaryTypographyProps={{ typography: 'caption' }}
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </Scrollbar>
          </MenuPopover>

          <Box
            sx={{
              top: 12,
              bottom: 12,
              right: 0,
              position: 'fixed',
              zIndex: (theme) => theme.zIndex.drawer + 2,
              ...(openCart && { right: 12 })
            }}
          >
            <Paper
              sx={{
                height: 1,
                width: '0px',
                overflow: 'hidden',
                boxShadow: (theme) => theme.customShadows.z24,
                transition: (theme) => theme.transitions.create('width'),
                ...(openCart && {
                  width: DRAWER_WIDTH, height: 640, overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '0.4em',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '4px',
                  },
                })
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 2, pl: 2 }}>
                <Typography variant="subtitle1">Your Cart</Typography>
                <MIconButton onClick={handleCartClose}>
                  <Icon icon={closeFill} width={20} height={20} />
                </MIconButton>
              </Stack>
              <Divider />
              <Stack sx={{ mb: 2.5 }}>
                <Stack spacing={1.5}>
                  <Stack>
                    <Box sx={{ mt: 0 }}>
                      <HomePageCartItem />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Box>
          {openLogin && (<JoyLoginPopUp openLogin={openLogin} setOpenLogin={setOpenLogin} handleCloseLogin={handleCloseLogin} />)}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
