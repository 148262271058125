const SENDGRID_API_KEY='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';
const senderMail="nushara@skyniche.com"
const BASE_URL='';

const portalName='';

const REST_API = 'https://loldao.com'

// const REST_API = 'http://localhost:5000'

export {SENDGRID_API_KEY,BASE_URL,portalName,senderMail,REST_API}

export const collectionsAddress = "0x724a580dbEeF0F1709667Dd6C0ac4F39bB09f7Bc";
export const NFTcollectionsAddress = "0xF4A44a1BFD168fa924c1488d1306df57da67a9DF";

// export const AImodelsAddress = "0x29C061a9e2b744bd6f741e0F66aEe3eEA304DF68";
export const AImodelsAddress = "0xa3997234cf70e679b04ceCe0f89F628F795EF52e";

// export const NFTmodelsAddress = "0xcc0006e8a44C24690b8500C5E8F34e05dcDe2212";
export const NFTmodelsAddress = "0xfB324Ed8C1eC29403bc357F51b7419cC89CAe74B";

export const ACCOUNT_PRIVATE_KEY='14b3637ed37d49dc74736be4a446046470ae957dbd6f8ce90d79327c19a7e694'
export const ALCHEMY_API_KEY=`https://polygon-amoy.g.alchemy.com/v2/KGz3a0WnAbxdQRNP6m5cdquEjge-nkvR`

export const infuraProjectId=`fb5198dda4c54cc586e56218fcd50c8b`

export const pinataApiKey = '2cdcda29fd05747d9ab6';
export const pinataSecretApiKey = '0b4c9110370682514badd5218df176168ddf052b475496dfaf058a377de51b82';

